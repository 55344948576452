import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import Spacing from '../../../components/spacing';
import { graphql, Link } from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Resources"
    >
      <p>A list of useful content and resources.</p>
      <Spacing>
        <p><Link to="raspberry-pi-battery-backup">Raspberry Pi Battery Backup</Link></p>
        <p><Link to="/felix">Channel Surfing for Netflix - Felix Chrome extension</Link></p>
      </Spacing>
      <Spacing>
        <p>Our supporters and friends:</p>
        <p><Link to="/e/17927/gng-computers-ltd">GNG Computers Ltd</Link></p>
        <p><Link to="/e/18298/steve-lock-ltd">Steve Lock Ltd</Link></p>
        <p><Link to="/e/36843/gamma-catering-equipment">Gamma Catering Equipment</Link></p>
        <p><Link to="/e/10475/anglia-refinishing-services">Anglia Refinishing Services</Link></p>
        <p><Link to="/e/36441/ignite-heating-spares-ltd">Ignite Heating Spares Ltd</Link></p>
        <p><Link to="/e/37017/laptop-repair-clinic">Computer Clinic</Link></p>
      </Spacing>
    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
